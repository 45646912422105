import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
import FormPage from './components/FormPage.vue';
import AdPage from './pages/AdsPage.vue';
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage
    },
    {
        path: '/form',
        name: 'Form',
        component: FormPage
    },
    {
      path: '/ads',
      name: 'Ads',
      component: AdPage
    }
  ]
});

export default router;

