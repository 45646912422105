        <template>
            <div>
                <h1>Ads</h1>
                <p>Here are the ads we are going to create:</p>
                <ul>
                    <li>olx Hugo 1</li>
                    <li>olx Hugo 2</li>
                    <li>olx Ana 1</li>
                    <li>olx Ana 2</li>
                    <li>olx Inês 1</li>
                    <li>olx Inês 2</li>
                    <li>olx Braga 1</li>
                    <li>olx Braga 2</li>
                    <li><a href="https://www.yescapa.pt/campers/67929" target="_blank">yescapa 1</a></li>
                    <li>yescapa 2</li>
                    <li>campanda</li>
                    <li>custojusto</li>
                    <li>idealista</li>
                    <li>imovirtual</li>
                    <li>sapo</li>
                    <li>google</li>
                    <li>facebook</li>
                    <li>instagram</li>
                    <li>airbnb</li>
                    <li>booking.com</li>
                    <li>tripadvisor</li>
                    <li>trivago</li>
                    <li>expedia</li>
                    <li>skyscanner</li>
                    <li>kayak</li>
                    <li>momondo</li>
                    <li>rentalcars</li>
                    <li>autoeurope</li>
                    <li>europcar</li>
                    <li>hertz</li>
                </ul>
                <!-- button to check if every ad is created -->
                <button>Check if every ad is created</button>
            </div>
        </template>

<script>
export default {

}
</script>

<style></style>